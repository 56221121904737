import React, { useEffect, useState } from "react";
import { TypeOfWindowUndefined } from "../components/_helpers";
import ReleaseLandingPageLoader from "../components/loaders/ReleaseLandingPageLoader";
import Track404 from "../components/notFoundPages/trackNotFound";
import ReleaseTemplate from "../components/templates/ReleaseTemplate";
import useGetNewestTrack from "../hooks/useGetNewestTrack";
import useSearchYouTube from "../hooks/useSearchYoutube";

export const song = {
  artist: "Truevined",
  artistUsername: "truevined",
  artistBio: "Singer, Rapper, Producer",
  featuredArtist: null,
  title: "No Time to Waste",
  artwork: "https://i.scdn.co/image/ab67616d0000b2734a7eed347f13c67d8c7213ae",
  audioURL:
    "https://p.scdn.co/mp3-preview/060448d0c0aabe899aaf624ff263e7b31ebb7def?cid=5225c4ca15f44b7aac57df853c6b5020",
  spotifyURL: "https://open.spotify.com/album/024rIBjGaM2l7tekgj9s6I?highlight=spotify:track:4MZVTzTBlvBPI28wdGniSH",
  appleMusicURL: "https://geo.music.apple.com/ca/album/_/1634706525?i=1634706736",
  // youtubeURL: "https://www.youtube.com/watch?v=clt-He56mOM",
  facebookPixel: "530282730968435",
};

function IndexPage() {
  if (TypeOfWindowUndefined()) {
    return null;
  }

  const [song, setSong] = useState(null);
  const [artistName, setArtistName] = useState(null);
  const [track, setTrack] = useState(null);

  useEffect(() => {
    let artist = "Truevined";
    setArtistName(artist);
    console.log("inside [homepage] useEffect");
  }, []);

  const trackData = useGetNewestTrack(
    {
      artistName: artistName,
    },
    null,
    [artistName]
  );

  const youtubeData = useSearchYouTube(
    {
      artistName: artistName,
      songName: track ? track.name : null,
    },
    null,
    [artistName, track]
  );

  useEffect(() => {
    if (trackData && artistName) {
      let searchResults = trackData.newestReleases[0];
      let previewUrl = trackData.previewUrl;
      let artistProfileUrl = trackData.artistProfileUrl;

      // search results is array of objects. Find the array that has tracks[index].album.name === tracks[index].name
      let track = searchResults;
      setTrack(track);
      console.log("track:", track);

      if (track) {
        let song = {
          artist: artistName,
          artistUsername: artistName,
          artistBio: "Singer, Rapper, Producer",
          // featuredArtist: null,
          title: track.name,
          artwork: track.images[0].url,
          audioURL: previewUrl,
          spotifyURL: track.external_urls.spotify,
          spotifyArtistURL: artistProfileUrl,
          // appleMusicURL: "https://geo.music.apple.com/ca/album/_/1634706525?i=1634706736",
          // youtubeURL: "https://www.youtube.com/watch?v=clt-He56mOM",
          // facebookPixel: "530282730968435",
          trackData: {
            tracks: [
              {
                album: trackData.trackData.album,
                artists: track.artists,
                duration_ms: trackData.trackData.duration_ms,
                explicit: trackData.trackData.explicit,
                external_ids: trackData.trackData.external_ids,
                name: trackData.trackData.name,
                popularity: trackData.trackData.popularity,
                preview_url: trackData.trackData.preview_url,
              },
            ],
          },
        };
        setSong(song);
      }
    }
  }, [trackData]);

  useEffect(() => {
    if (youtubeData && !youtubeData.error && song) {
      let youtubeURL = youtubeData.items[0].id.videoId;
      let songWithYoutube = { ...song, youtubeURL: youtubeURL };
      setSong(songWithYoutube);
    }
  }, [youtubeData]);

  if (!trackData) {
    return <ReleaseLandingPageLoader />;
  }

  if (trackData && !track) {
    return <Track404 song={{ artist: artistName, title: "" }} />;
  }

  if (trackData && track) {
    return <ReleaseTemplate song={song} showGrain />;
  }
}

export default IndexPage;
